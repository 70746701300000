import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { usePathPattern } from "../App";
import { FullScreenLoader, HandleScrollPosition } from "../components";
import { AuthContext } from "../contexts/AuthContext/AuthContext";
import useHomeRoute from "../hooks/useHomeRoute";
import useLoader from "../hooks/useLoader";
import { getUser, setCurrentUserNull } from "../redux/slices/CurrentUserSlice";
import { AppDispatch, RootState } from "../redux/store";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import {
  AcceptInvitation,
  CreateRequestScreen,
  EmailVerified,
  FeesScreen,
  ForgotPassword,
  GuestLoginScreen,
  GuestVerifyPhone,
  GuestViewRequest,
  Landing,
  LoginScreen,
  NotFoundScreen,
  OfficeDetailsScreen,
  OfficeInfo,
  OfficesScreen,
  OfficeTeam,
  PatientDashboard,
  PatientViewRequest,
  ResetPassword,
  ServiceScreen,
  SingleOffice,
  SubmitRequest,
  ThankYou,
  UserScreen,
  VerifyEmail,
  VerifyPhone,
  ViewRequest,
  AdminFinanceScreen,
} from "../screens";
import FinanceScreen from "../screens/NewFinanceScreen/FinanceScreen";
import PrivacyPolicy from "../screens/Guest/PrivacyPolicy";
import TermsAndConditions from "../screens/Guest/TermsAndCondition";
import OfficePatients from "../screens/OfficePatients/OfficePatients";
import { apiService } from "../services/api.service";
import { UserType } from "../services/user.service";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../utils/constants";
import ProtectedOnboardingRoute from "./ProtectedOnboardingRoute";
import ProtectedRoute from "./ProtectedRoute";
import CreateAccount from "../screens/Onboarding/CreateAccount";
import OfficePlan from "../screens/OfficePlan/OfficePlan";
import { PatientInvitation } from "../screens/PatientInvitation";
import HeaderNew from "../components/Header/HeaderNew";
import LeftMenu from "../components/Menu/LeftMenu";
import Doctors from "../screens/Doctors";
import { ContactsScreen } from "../screens/ContactsScreen";
import ViewPatient from "../screens/ViewPatient/ViewPatient";
import Activate from "../screens/Activate";
import SearchScreen from "../screens/SearchScreen/SearchScreen";
import FormsScreen from "../screens/FormsScreen/FormsScreen";
import PatientRequests from "../screens/PatientDashboard/PatientRequests";
import CreateGuestAccount from "../screens/Onboarding/CreateGuestAccount";
import PatientProfile from "../screens/PatientDashboard/PatientProfile";
import PatientAccountSettings from "../screens/PatientDashboard/PatientAccountSettings";
import PatientDocuments from "../screens/PatientDocuments/PatientDocuments";
import PatientReceipts from "../screens/PatientReceipts/PatientReceipts";
import CreatePatientRequest from "../screens/CreatePatientRequest/CreatePatientRequest";
import { getUserLastAccessDate } from "../redux/slices/UserSlice";

export const userPaths = [
  { path: "/offices/:id/request" },
  { path: "/offices/:id/services" },
  { path: "/offices/:id/fees" },
  { path: "/offices/:id/plans" },
  { path: "/offices/:id/requests/:requestId" },
  { path: "/offices/:id/patients" },
  { path: "/offices/:id/forms" },
];

export const patientPaths = [
  { path: "/patients/:patientId/dashboard" },
  { path: "/patients/:patientId/requests" },
  { path: "/patients/:patientId/requests/:requestId" },
  { path: "/patients/:id/documents" },
  { path: "/patients/:id/plans" },
  { path: "/patients/:id/receipts" },
  // { path: "/patients/:id/resources" },
];

export const adminPaths = [
  { path: "/offices/" },
  { path: "/users" },
  { path: "/services" },
  { path: "/fees" },
  { path: "/finance" },
  { path: "/search" },
  { path: "/all-patients" },
  { path: "/forms" },
  { path: "/offices/:id/requests" },
  { path: "/offices/:id/services" },
  { path: "/offices/:id/team" },
  { path: "/offices/:id/patients" },
  { path: "/offices/:id/finance" },
  { path: "/offices/:id/office-info" },
  { path: "/offices/:id/requests/:requestId" },
  { path: "/offices/:id/requests/create-request" },
  { path: "/offices/:id/plans" },
  { path: "/offices/:id/contacts" },
  { path: "/offices/:id/patients/:patientId" },
  { path: "/offices/:id/forms" },
];
export const systemAdminPaths = [
  { path: "/offices/" },
  { path: "/users" },
  { path: "/services" },
  { path: "/fees" },
  { path: "/all-patients" },
  { path: "/search" },
  { path: "/finance" },
  { path: "/forms" },
];
export const hideLoginInHeaderPaths = ["/guest/view-request", "/guest/verify-phone", "invitation"];

const RootNavigator = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
  const location = useLocation();
  const redirectPath = localStorage.getItem("redirectPath");
  const dispatch: AppDispatch = useDispatch();
  const loader = useLoader();
  const { homeLink } = useHomeRoute();
  const match = usePathPattern();

  const noHeader =
    location?.pathname === "/" ||
    location.pathname === "/onboarding/office" ||
    location.pathname === "/doctors" ||
    location.pathname === "/activate" ||
    location.pathname === "/patients-new";

  const showLeftMenu = auth?.isAuthenticated && !noHeader;

  const isSystemAdminPath = () => {
    return systemAdminPaths.filter(it => it.path === match.path).length;
  };
  const isUserPath = () => {
    return userPaths.filter(it => it.path === match.path).length > 0;
  };

  const isPatientPath = () => {
    return patientPaths.filter(it => it.path === match.path).length > 0;
  };

  const onClick = (val: boolean) => {
    setShowMenu(val);
  };

  const callUserGuiding = async () => {
    if (process.env.REACT_APP_ENV !== "development") return;

    const token = localStorage.getItem(ACCESS_TOKEN);
    const windowObj = window as any;
    if (token === null && currentUser && windowObj && windowObj?.userGuiding && windowObj?.userGuiding?.identify) {
      const result = await dispatch(getUserLastAccessDate({ userId: currentUser.userId }));
      windowObj?.userGuiding?.identify(currentUser.userId, {
        userType: currentUser.userType,
        company: {
          id: currentUser?.offices?.length ? currentUser?.offices[0].officeId : null,
        },
        lastAccessDate: result.payload,
      });
    }
  };

  useEffect(() => {
    // Route tracking for GA
    ReactGA.pageview(location.pathname + window.location.search);
  }, [location]);

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    apiService.init(accessToken ? accessToken : "", refreshToken);
    if (!location.pathname.includes("invitation")) auth?.isAlreadyLoggedIn();
    setInitialised(true);
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/patients") || location.pathname.includes("/offices")) {
      localStorage.setItem("redirectPath", location.pathname);
    }
    if (auth?.isAuthenticated === true && !location.pathname.includes("invitation")) {
      dispatch(getUser());
    } else {
      dispatch(setCurrentUserNull());
    }
  }, []);

  useEffect(() => {
    if (screenWidth < 1024 && showLeftMenu) {
      onClick(false);
    }
  }, [location.pathname, screenWidth, showLeftMenu]);

  useEffect(() => {
    const updateDimension = () => {
      if ((window.innerWidth < 1023 && screenWidth > 1023) || (window.innerWidth > 1023 && screenWidth < 1024)) setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenWidth]);

  useEffect(() => {
    if (
      initialised &&
      currentUser &&
      window.location.href.includes(`${window.location.origin}/patients`) &&
      currentUser?.userType !== UserType.Patient
    ) {
      localStorage.clear();
      navigate(location.pathname);
    }
  }, [initialised, location, currentUser]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      const token = localStorage.getItem(ACCESS_TOKEN);
      const windowObj = window as any;
      if (token === null && currentUser && windowObj && windowObj?.userGuiding) {
        callUserGuiding();
      }
    }
  }, [currentUser]);

  if (!initialised) return null;

  let redirect = "";
  if (currentUser !== undefined && auth?.isAuthenticated) {
    if (currentUser?.userType === UserType.User && !isUserPath()) redirect = homeLink;
    else if (currentUser?.userType === UserType.Patient && !isPatientPath()) {
      redirect = redirectPath === "/login" ? homeLink : redirectPath || "";
    } else if (currentUser?.userType === UserType.OfficeAdmin && isSystemAdminPath()) redirect = homeLink;
    else if (currentUser?.userType === UserType.Accounting && isSystemAdminPath()) redirect = homeLink;
    else if (currentUser?.userType === UserType.SystemAdmin && isSystemAdminPath()) {
      redirect = redirectPath === "/login" ? "" : redirectPath || "";
      localStorage.removeItem("redirectPath");
    }
  }

  return (
    <>
      <div className={`${!noHeader ? "main-container container" : ""}`}>
        {!noHeader ? (
          <>
            {showLeftMenu ? (
              <span
                className={`humburger cursor-pointer ${showMenu ? "active" : ""}`}
                onClick={e => {
                  e.preventDefault();
                  onClick(!showMenu);
                }}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            ) : null}
            <HeaderNew showLeftMenu={showLeftMenu && screenWidth < 1024} />
            <HandleScrollPosition />
          </>
        ) : null}
        {loader ? <FullScreenLoader /> : null}
        <div className="flex relative">
          {showLeftMenu ? (
            <div className={`w-2/12 leftside-panel ${showMenu ? "leftside-panel-show" : "leftside-panel-hide"}`}>
              <LeftMenu screenWidth={screenWidth} />
            </div>
          ) : null}
          <div className={`maincontainer-panel ${showLeftMenu ? "w-10/12" : "w-full"} ${!noHeader ? "bg-white rounded-[30px]" : ""}`}>
            <Routes>
              <Route path="/omddhc2023" element={<Navigate to="/doctors" replace />} />
              <Route path="/omd" element={<Navigate to="/doctors" replace />} />
              <Route path="/demo" element={<Navigate to="/activate" replace />} />
              <Route path="/" element={!currentUser ? <Landing /> : <Navigate to={homeLink} />} />
              <Route path={"create-account"} element={<ProtectedOnboardingRoute />}>
                <Route index element={<CreateAccount />} />
              </Route>
              <Route path={"onboarding"} element={<ProtectedOnboardingRoute />}>
                <Route path={"office"} element={<SingleOffice />} />
                <Route path={"create-account"} element={<CreateAccount />} />
                <Route path={"create-guest-request"} element={<CreateGuestAccount />} />
                <Route path={"verify-email"} element={<VerifyEmail />} />
                <Route path={"verify-success"} element={<EmailVerified />} />
                <Route path={"submit-request"} element={<SubmitRequest />} />
                <Route path={"verify-phone"} element={<VerifyPhone />} />
                <Route path={"thank-you"} element={<ThankYou />} />
              </Route>
              <Route path="doctors" element={<Doctors />} />
              <Route path="activate" element={<Activate />} />
              <Route path="invitation/:invitationToken" element={<AcceptInvitation />} />
              <Route path="patient-invitation/:invitationToken" element={<PatientInvitation />} />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              {/* accessible by patient user only */}
              <Route path="patients/:patientId" element={<ProtectedRoute userRoles={[UserType.Patient]} />}>
                <Route index element={<Navigate to="dashboard" />} />
                <Route path="dashboard">
                  <Route index element={homeLink !== "" ? <PatientDashboard /> : <Navigate to={redirect} />} />
                  <Route path="profile" element={<PatientProfile />} />
                  <Route path="account-settings" element={<PatientAccountSettings />} />
                </Route>
                <Route path="documents" element={<PatientDocuments />} />
                <Route path="receipts" element={<PatientReceipts />} />
                <Route path="request/:patientRequestId">
                  <Route index element={homeLink !== "" ? <PatientViewRequest /> : <Navigate to={redirect} />} />
                </Route>
                <Route path="requests">
                  <Route index element={homeLink !== "" ? <PatientRequests /> : <Navigate to={redirect} />} />
                  <Route path=":patientRequestId" element={homeLink !== "" ? <PatientViewRequest /> : <Navigate to={redirect} />} />
                  <Route path="create-request" element={<CreatePatientRequest />} />
                </Route>
              </Route>
              {/* Only accessible by admin and office admin */}
              <Route path="offices" element={<ProtectedRoute userRoles={[UserType.SystemAdmin, UserType.OfficeAdmin]} />}>
                <Route index element={redirect === "" ? <OfficesScreen /> : <Navigate to={redirect} />} />
              </Route>
              {/* accessible by admin, office admin, accountant and office user */}
              <Route
                path="offices/:id"
                element={<ProtectedRoute userRoles={[UserType.SystemAdmin, UserType.OfficeAdmin, UserType.Accounting, UserType.User]} />}>
                <Route index element={<Navigate to="requests" />} />
                <Route path="team" element={<OfficeTeam />} />
                <Route path="patients">
                  <Route index element={redirect === "" ? <OfficePatients isAdminPage={false} /> : <Navigate to={redirect} />} />
                  <Route path={":patientId"}>
                    <Route index element={redirect === "" ? <ViewPatient /> : <Navigate to={redirect} />} />
                  </Route>
                </Route>
                <Route path="office-info" element={<OfficeInfo />} />
                <Route path="finance" element={<FinanceScreen />} />
                <Route path={"fees"} element={redirect === "" ? <FeesScreen isAdminPage={false} /> : <Navigate to={redirect} />} />
                <Route path="requests">
                  <Route index element={<OfficeDetailsScreen />} />
                  <Route path="create-request" element={<CreateRequestScreen />} />
                  <Route path=":requestId" element={redirect === "" ? <ViewRequest /> : <Navigate to={redirect} />} />
                </Route>
                <Route path="services" element={<ServiceScreen isAdminPage={false} />} />
                <Route path="resources" element={<NotFoundScreen />} />
                <Route path="plans" element={<OfficePlan />} />
                <Route path="contacts" element={<ContactsScreen />} />
                <Route path={"forms"} element={redirect === "" ? <FormsScreen /> : <Navigate to={redirect} />} />
              </Route>
              {/* Only accessible by admin and office admin start */}
              <Route path={"fees"} element={<ProtectedRoute userRoles={[UserType.OfficeAdmin, UserType.SystemAdmin]} />}>
                <Route index element={redirect === "" ? <FeesScreen isAdminPage /> : <Navigate to={redirect} />} />
              </Route>
              <Route path={"finance"} element={<ProtectedRoute userRoles={[UserType.OfficeAdmin, UserType.SystemAdmin]} />}>
                <Route index element={redirect === "" ? <AdminFinanceScreen /> : <Navigate to={redirect} />} />
              </Route>
              <Route path={"users"} element={<ProtectedRoute userRoles={[UserType.OfficeAdmin, UserType.SystemAdmin]} />}>
                <Route index element={redirect === "" ? <UserScreen /> : <Navigate to={redirect} />} />
              </Route>
              <Route path={"services"} element={<ProtectedRoute userRoles={[UserType.OfficeAdmin, UserType.SystemAdmin]} />}>
                <Route index element={redirect === "" ? <ServiceScreen isAdminPage /> : <Navigate to={redirect} />} />
              </Route>
              <Route path={"search"} element={<ProtectedRoute userRoles={[UserType.OfficeAdmin, UserType.SystemAdmin]} />}>
                <Route index element={redirect === "" ? <SearchScreen /> : <Navigate to={redirect} />} />
              </Route>
              <Route path={"forms"} element={<ProtectedRoute userRoles={[UserType.OfficeAdmin, UserType.SystemAdmin]} />}>
                <Route index element={redirect === "" ? <FormsScreen isAdminPage /> : <Navigate to={redirect} />} />
              </Route>
              {/* Only accessible by admin and office admin end */}
              {/* Only accessible by guest */}
              <Route path="guest" element={<ProtectedOnboardingRoute />}>
                <Route path="login" element={<GuestLoginScreen />} />
                <Route path={"verify-phone"} element={<GuestVerifyPhone />} />
                <Route path="view-request" element={<GuestViewRequest />} />
              </Route>
              <Route path={"login"} element={auth?.isAuthenticated ? <Navigate to={homeLink} /> : null}>
                <Route index element={<LoginScreen />} />
              </Route>
              <Route path="forgot-password" element={!auth?.isAuthenticated ? <ForgotPassword /> : <Navigate to={homeLink} />} />
              <Route path="reset-password" element={!auth?.isAuthenticated ? <ResetPassword /> : <Navigate to={homeLink} />} />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default RootNavigator;
